import React, { useState } from "react";
import { Link } from "react-router-dom";
import Navbar from "./Navbar";
import { HiOutlineMenuAlt1 } from "react-icons/hi";
import Logo from "../assets/logo.png";

const Header = () => {
  const [showMenu, setShowMenu] = useState(false);
  return (
    <>
      <div className="flex justify-between bg-transparent  sm:px-[5em] px-[2em] w-full h-[80px] items-center z-[10]">
        <Link to="/">
          <img src={Logo} alt="Logo" className="h-[50px]" />
        </Link>

        <div className="hidden space-x-4 sm:flex mr-[4em]">
          <Link to="/">Home</Link>
          <Link to="/services">Services</Link>
          <Link to="./contact">Contact Us</Link>
        </div>

        <div onClick={() => setShowMenu(true)} className="cursor-pointer sm:hidden flex">
          <HiOutlineMenuAlt1 size={30} />
        </div>
      </div>
      <Navbar showMenu={showMenu} setShowMenu={setShowMenu} />
    </>
  );
};

export default Header;
