import React from "react";
// import { Link } from "react-router-dom";
import Close from "../assets/close.svg";
import { motion, AnimatePresence } from "framer-motion";

const socialData = [
  { id: 3, name: "Twitter", path: "https://twitter.com/SAMEConsultancy" },
  {
    id: 4,
    name: "Instagram",
    path: "https://instagram.com/sameconsultancy?igshid=YmMyMTA2M2Y=",
  },
];

const links = [
  { id: 1, name: "Home", path: "/" },
  { id: 3, name: "Services", path: "/services" },
  { id: 5, name: "Contact Us", path: "/contact" },
];

const backdrop = {
  visible: { opacity: 1 },
  hidden: {
    opacity: 0,
    transition: {
      duration: 1,
    },
  },
};
const menuAnimate = {
  scale: [1, 2, 2, 1, 1],
  rotate: [0, 0, 270, 270, 0],
  borderRadius: ["20%", "20%", "50%", "50%", "20%", "50%"],
};

const modal = {
  hidden: {
    y: "-100vh",
    opacity: 0,
    transition: {
      duration: 0.6,
    },
  },
  visible: {
    y: "0vh",
    opacity: 1,
    transition: {
      delay: 0.5,
      duration: 0.6,
    },
  },
};

const listy = {
  visible: {
    y: "0",
    opacity: 1,
    delay: 5,
    transition: {
      delay: 0.5,
      duration: 0.6,
    },
  },
};

const Navbar = ({ showMenu, setShowMenu }) => {
  return (
    <AnimatePresence mode="wait" onExitComplete={() => setShowMenu(false)}>
      {showMenu && (
        <div className=" ">
          <motion.div
            variants={backdrop}
            animate="visible"
            initial="hidden"
            exit="hidden"
            className="fixed w-full h-[100vh] bg-heroColor top-0 overflow-y-scroll left-0 z-50"
          >
            <motion.div
              variants={modal}
              className="md:w-[60%] sm:w-[90%] w-[100%] h-[100%] overflow-y-scroll  bg-white float-right pt-[1em]"
            >
              <div className="flex justify-between px-8">
                <div></div>
                <motion.div
                  animate={menuAnimate}
                  transition={{ duration: 2 }}
                  className="border-black border-2 p-4 rounded-[50%] cursor-pointer"
                  onClick={() => setShowMenu(false)}
                >
                  <img src={Close} alt="" />
                </motion.div>
              </div>
              <div className="mt-[5em]">
                <div className="flex w-[70%] mx-auto justify-between">
                  <div>
                    <p className="text-[16px] text-[#808080]">Social</p>
                    <div className="flex flex-col mt-4 text-primary">
                      {socialData.map((data) => {
                        const { id, name, path } = data;
                        return (
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href={path}
                            key={id}
                            className="py-2 md:text-[16px] text-[14px]"
                          >
                            {name}
                          </a>
                        );
                      })}
                    </div>
                  </div>

                  <div>
                    <p className="text-[16px] text-[#808080]">Menu</p>
                    <motion.ul
                      variants={listy}
                      animate="visible"
                      className="flex flex-col mt-4 text-primary"
                    >
                      {links.map((data) => {
                        const { id, name, path } = data;
                        return (
                          <motion.li
                            whileHover={{
                              scale: 1.3,
                              originX: 0,
                              color: "#E6B535",
                            }}
                            transition={{ type: "spring", stiffness: 300 }}
                            animate="visible"
                            key={id}
                            className="cursor-pointer"
                          >
                            <a
                              href={path}
                              className="sm:text-[38px] text-[28px] font-medium"
                            >
                              {name}
                            </a>
                          </motion.li>
                        );
                      })}
                    </motion.ul>
                  </div>
                </div>
                <div className="w-[80%] mx-auto text-primary">
                  <p className="text-[#808080] mt-12 mb-4">Shoot a mail</p>
                  <a href="mailto:samekonnect@gmail.com" className="underline">
                    samekonnect@gmail.com
                  </a>
                </div>
              </div>
            </motion.div>
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  );
};

export default Navbar;
