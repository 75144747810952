import React from "react";
import { Link } from "react-router-dom";
import {
  FaTwitter,
  FaInstagram,
} from "react-icons/fa";
import { FiMail } from "react-icons/fi";

const Footer = () => {
  return (
    <div className="py-[3em] bg-aux text-primary">
      <div className="w-[80%] mx-auto text-center">
        <h1>SAME Consultancy</h1>
        <ul className="flex list-none justify-center gap-[1em] text-[12px] my-[2em]">
          <Link to="/">Home</Link>
          <Link to="/blog">Blog</Link>
          <Link to="/services">Services</Link>
          <Link to="/contact">Contact us</Link>
          <Link to="/faq">FAQ</Link>
        </ul>

        <div className="flex space-x-5 mx-auto w-[100%] items-center justify-center my-[2em]">
          <a
            href="https://twitter.com/SAMEConsultancy"
            target="_blank"
            rel="noreferrer"
          >
            <FaTwitter />
          </a>
          <a href="mailto:samekonnect@gmail.com">
            <FiMail />
          </a>

          <a
            href="https://instagram.com/sameconsultancy?igshid=YmMyMTA2M2Y="
            target="_blank"
            rel="noreferrer"
          >
            <FaInstagram />
          </a>
          
        </div>

        <p className="text-[10px]">
          Copyright &copy; 2023, SAME Consultancy. All rights reserved.
        </p>
      </div>
    </div>
  );
};

export default Footer;
