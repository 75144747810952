import React, { useRef } from "react";
import jsPDF from "jspdf";
import Button from "../components/Button";
import { useStore } from "../store";
import { formatAccount } from "../utils/formatAccount";
import moment from "moment";
import Logo from "../assets/logo.png"


const columns = [
  { field: "sn", header: "S/N" },
  { field: "description", header: "DESCRIPTION" },
  { field: "price", header: "PRICE" },
  { field: "qty", header: "QTY" },
  { field: "total", header: "TOTAL" },
];



const Invoice = () => {
  const report = useRef(null);
  const {userData, table} = useStore()


  const contactData = table.map((el, i) => ({
    id: el?._id,
    sn: i + 1,
    description: el.serviceName,
    price: formatAccount.format(+el.servicePrice),
    qty:  1,
    total:  formatAccount.format(+el.servicePrice),
  }));


  // console.log(userData, "Invoice", table, contactData)

  const handlePdf = () => {
    const doc = new jsPDF({
      orientation: "portriat",
      unit: "px",
      format: [1000, 1200],
    });

    doc.setFont("Montserrat");

    doc.html(report.current, {
      async callback(doc) {
        await doc.save("document");
      },
      x: 12,
      y: 12,
    });
  };
  return (
    <div className="bg-white text-black min-w-[1200px] py-[4em]">
      <div className="w-[80%] mx-auto" ref={report}>
        <p className="text-[64px] text-center font-secondary text-secondary">
          Invoice
        </p>

        <div className="flex justify-between mt-[3em]">
          <div className="flex w-[40%]">
            <p className="font-[500] text-[14px] self-end mr-4">NO:</p>
            <div className="w-[100%] flex flex-col space-y-2">
              <p className="text-center font-secondary text-secondary text-[28px]">
                001
              </p>
              <hr className="border-[0.5px] border-black w-[100%]" />
            </div>
          </div>

          <div className="flex w-[40%] ">
            <p className="font-[500] text-[14px] self-end mr-4">DATE:</p>
            <div className="w-[100%]  flex flex-col space-y-2">
              <p className="text-center font-secondary text-secondary text-[28px]">
               {moment().format('MMMM Do YYYY')}
              </p>
              <hr className="border-[0.5px] border-black w-[100%]" />
            </div>
          </div>
        </div>

        <div className="flex justify-between text-[14px] font-[600] mt-[3em]">
          <div className="w-[45%]">
            <p>FROM:</p>

            <div className="border-[1px] border-black mt-2">
              <p className="border-b-[1px] border-black px-[1em] py-2">
                
                SAME Consultancy
              </p>
              <p className="px-[1em] py-2">samekonnect@gmail.com</p>
            </div>
          </div>

          <div className="w-[45%]">
            <p>TO:</p>

            <div className="border-[1px] border-black mt-2">
              <p className="border-b-[1px] border-black px-[1em] py-2">
              {userData.name}
              </p>
              <p className="px-[1em] py-2">           {userData.email}</p>
            </div>
          </div>
        </div>


        <table className="w-[100%] mt-[4em]">
              <thead className=" text-[14px] bg-primary text-white  border-b-[1px] ">
                <tr className=" h-[45px] px-7 text-left">
                  {columns &&
                    columns.map((head, i) => (
                      <th key={i} className="pl-4 border-r-[1px] font-[400]">
                        {head.header}{" "}
                      </th>
                    ))}
                </tr>
              </thead>

              <tbody>
                {contactData &&
                  contactData.map((row, i) => (
                    <tr key={i} className="h-[50px] border-b-[1px] text-[14px] text-primary font-[500] bg-white">
                      {columns?.map((col, i) => (
                        <td key={i} className="pl-4 border-r-[1px] ">
                          {row[col.field]}
                        </td>
                      ))}
                    </tr>
                  ))}
              </tbody>
            </table>

       

        <div className="border-[1px] border-black my-[4em] w-[60%]">
          <p className="bg-primary text-white p-2 font-[600]">Notes</p>
          <p className="pb-[3em] px-2 pt-2 text-[14px]">
            Thank you for choosing SAME Consultancy. Please, do not hesitate to
            contact us for any questions.
          </p>
        </div>

        <div className="text-center text-primary font-[500]  mt-[2em]">
          <p className="text-[48px] font-secondary">Thank you.</p>
          <p className="py-[2em] underline">Please, pay within 10 days</p>
          <p>Account Name: Adelakun Jonathan Oluwadara</p>
          <p>Bank: GTBank</p>
          <p>Account Number: 005-094-6869</p>
        </div>

            <img src={Logo} alt="Logo" className="h-[100px] mx-auto" />
      </div>

      <div
        className="w-[100%] flex justify-center items-center mt-[2em]"
        onClick={handlePdf}
      >
        <Button text="Download Invoice" />
      </div>
    </div>
  );
};

export default Invoice;
