import React, { useState } from "react";
import Button from "./Button";
import Modal from "./Modal";
import { formatAccount } from "../utils/formatAccount";

const Pricing = ({ pricing, title, img }) => {
  const [modalOpen, setModalOpen] = useState(false);

  const openModal = () => {
    setModalOpen(!modalOpen);
  };
  return (
    <>
      <div className="bg-primary rounded-[5px] overflow-hidden h-[350px] w-[100%]">
        <div className="h-[50%] w-[100%] overflow-hidden">
          <img src={img} alt="img" className="object-contain" />
        </div>

        <div className="p-4">
          <p className="text-[12px]">{title}</p>
          <p className="text-[12px]">{formatAccount?.format(pricing)}</p>

          <p className="w-[100%] bg-aux py-[0.5px] mx-auto mt-[1em] mb-[2em] rounded text-[14px]"></p>
          <Button text="Book Now" onClick={openModal} />
        </div>
      </div> 

      {modalOpen ? <Modal openModal={openModal} pricing={pricing} title={title} /> : null}
    </>
  );
};

export default Pricing;
