import { FiMail, FiTwitter, FiPhoneCall } from "react-icons/fi";
import Pricing from "../components/Pricing";
import Button from "../components/Button";
import { Link } from "react-router-dom";

function Home() {
  return (
    <div className="font-primary text-white bg-primary">
      {/* <Header/> */}
      <div className="relative">
        <div className="bg-heroColor absolute top-0 left-0 w-full h-full flex justify-center items-center flex-col">
          <h1 className="sm:text-[64px] text-[36px] text-center">
            SAME Consultancy.
          </h1>
          <p className="mb-4 w-[80%] text-center">
            Study abroad guidance || Work visa guide || Post visa tutorials
          </p>
          <Link to="/services">
            <Button text="Book An Appointment" />
          </Link>
        </div>

        <div className="bg-Hero h-[100vh] bg-no-repeat w-full bg-center bg-cover"></div>
      </div>

      <div className="w-[80%] mx-auto">
        <div className="flex md:flex-row flex-col justify-between items-center sm:my-[2em] my-[0] ">
          <div className="md:w-[50%] w-[100%] md:text-left text-center md:mt-0 mt-[1em]">
            <p
              className="text-[16px] font-[300] py-4 md:w-[100%] w-[100%] leading-[30px]"
              data-aos="fade-in"
              data-aos-duration="2000"
            >
              SAME Consultancy stands for Study Abroad Made Easy. As the name
              implies, it is an agency that has been uniquely positioned to make
              migration for studying and work purposes easy. Essentially, the
              start-up provides personalized admission and visa processing
              services to individuals who intend to study abroad.
            </p>

            <p
              className="text-[16px] font-[300] py-4 md:w-[100%] w-[100%] leading-[30px]"
              data-aos="fade-in"
              data-aos-duration="2500"
            >
              The goal is to go further than just helping these applicants
              fulfill their study abroad dreams. The company also provides work
              visa processing services for nurses in the UK and also ensures to
              coach prospective candidates on the modules of the IELTS exam. The
              countries that the company currently focuses on includes Belgium,
              Germany and the United Kingdom.
            </p>
          </div>

          <div
            className="md:w-[35%] w-[100%] h-[500px] md:mt-0 mt-4 overflow-hidden"
            data-aos="zoom-in"
            data-aos-duration="3000"
          >
            <img
              src="https://as2.ftcdn.net/v2/jpg/03/01/56/47/1000_F_301564720_vDGaaacEGplbnI2jcsk2kPM6xgGgJW04.jpg"
              alt="Hero"
              className="w-[100%] h-[100%] object-cover rounded-[20px] shadow-lg"
              data-aos="fade-in"
              data-aos-duration="4000"
            />
          </div>
        </div>
      </div>
      <div className=" bg-aux py-[5em]">
        <div className="w-[80%] mx-auto">
          <h1 className="text-[28px] text-primary">Our Services.</h1>
          <div className="bg-secondary h-[3px] w-[100px] mb-[3em]"></div>

          <div className="grid sm:grid-cols-3 grid-col-1 items-center justify-items-center gap-[3em] mt-4 w-[100%]">
          <Pricing
              pricing=""
              title="VISA APPLICATION (work visa)"
              img="https://images.unsplash.com/photo-1581553673739-c4906b5d0de8?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8dmlzYXxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=900&q=60"
            />
            <Pricing
              pricing="30000"
              title="CONSULTATION REPORT (for studies abroad)"
              img="https://images.unsplash.com/photo-1578574577315-3fbeb0cecdc2?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NHx8Y29uc3VsdGluZ3xlbnwwfHwwfHw%3D&auto=format&fit=crop&w=900&q=60"
            />
            <Pricing
              pricing="50000"
              title="CONSULTATION REPORT (for work)"
              img="https://images.unsplash.com/photo-1584432810601-6c7f27d2362b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8bnVyc2V8ZW58MHx8MHx8&auto=format&fit=crop&w=900&q=60"
            />
            <Pricing
              pricing="2500"
              title="IT TUTORIAL CLASSES (per hour)"
              img="https://images.unsplash.com/photo-1585829365343-ea8ed0b1cb5b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80"
            />
           
            <Pricing
              pricing="2000"
              title="IELTS LESSON (per hour)"
              img="https://images.unsplash.com/photo-1501504905252-473c47e087f8?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NHx8bGVzc29ufGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=900&q=60"
            />
          </div>
        </div>
      </div>

      <div className="py-[4em]">
        <div className="sm:w-[65%] w-[90%] mx-auto bg-aux text-primary py-[6em] sm:px-[4em] px-[2em] rounded-[1em]">
          <h1 className="text-[28px] text-center">Get In Touch.</h1>
          <div className="bg-secondary h-[3px] w-[100px] mx-auto"></div>

          <p className="text-center my-4">
            How can we help you? We'd love to hear from you
          </p>

          <div className="flex justify-between items-center mt-8 sm:w-[80%] w-[100%] mx-auto sm:flex-row flex-col">
            <div className="">
              <div className="text-[12px] rounded-[20px] flex items-center bg-[#0cb1be] py-2 px-4">
                <FiMail />
                <p className="ml-4">info@example.com</p>
              </div>

              <div className="text-[12px] rounded-[20px] flex items-center bg-[#2ec37e] py-2 px-4 my-4">
                <FiTwitter /> <p className="ml-4">@SAMEConsultancy</p>
              </div>

              <div className="text-[12px] rounded-[20px] flex items-center bg-[#2d6cc5] py-2 px-4">
                <FiPhoneCall />
                <p className="ml-4">+234 312 3234 234</p>
              </div>
            </div>

            <div className="h-[150px] rounded w-[2px] bg-secondary sm:block hidden"></div>
            <div className="text-[14px] sm:mt-0 mt-[2em] ">
              <p>
                Lorem ipsum dolor sit,
                <br /> amet consectetur adipisicing
                <br /> elit. Unde, repellat.
                <br /> Belgium
              </p>
            </div>
          </div>
        </div>
      </div>

      <div></div>
    </div>
  );
}

export default Home;
