import { create } from "zustand";
import { devtools } from "zustand/middleware";

const initialValues = {
  name: "",
  email: "",
  phone: "",
  table: [
    {
      serviceName: "",
      servicePrice: "",
    },
  ],
};
export const useStore = create(
  devtools((set) => ({
    userData: initialValues,
    setUserData: (data) => {
      set(() => ({ userData: data }));
    },
    table: [],
    setTable: (serviceName, servicePrice) => {
      set((state) => ({
        table: [
          ...state.table,
          {
            serviceName,
            servicePrice,
      
          },
        ],
      }));
    },
  }))
);
