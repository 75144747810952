import { BrowserRouter, Routes, Route } from "react-router-dom";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Blog from "./pages/Blog";
import Index from "./pages/index";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Services from "./pages/Services";
import Faq from "./pages/Faq";
import Invoice from "./pages/Invoice";
import WebsiteLayout from "./layouts/WebsiteLayout";

function App() {
  return (
    <div className="font-primary text-white bg-primary">
      <div className="font-primary">
        <BrowserRouter>
          {/* <Header /> */}
          <div className="pt-[0em]">
            <Routes>
              <Route element={<WebsiteLayout />}>
                <Route path="/" element={<Index />} />
                <Route path="/blog" element={<Blog />} />
                <Route path="/about" element={<About />} />
                <Route path="/services" element={<Services />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/faq" element={<Faq />} />
               
              </Route>
              <Route path="/invoice" element={<Invoice />} />
            </Routes>

      
          </div>

          {/* <Footer /> */}
        </BrowserRouter>
      </div>
    </div>
  );
}

export default App;
