import React from 'react'

const Button = ({text, onClick}) => {
  return (
    <button className="text-white bg-secondary px-4 py-2 rounded-[4px] text-[12px] cursor-pointer" onClick={onClick}>
      {text}
    </button>
  )
}

export default Button