import React, { useState } from "react";
import Button from "./Button";
import Input from "./Input";
// import { usePaystackPayment } from "react-paystack";
// import { onClose, onSuccess } from "../utils/Paystack";
import { FaRegTimesCircle } from "react-icons/fa";
// import { formatAccount } from "../utils/formatAccount";
import { useStore } from "../store";
import { useNavigate } from "react-router-dom";

// const publicKey = process.env.VITE_PAYSTACK_PUBLIC_KEY;

const Modal = ({ openModal, pricing, title }) => {
  const navigate = useNavigate();
  const { setUserData, setTable } = useStore();
  const [config, setConfig] = useState({
    name: "",
    email: "",
  });

  const handleChange = (e) => {
    const valueName = e.target.name;
    const value = e.target.value;
    setConfig({ ...config, [valueName]: value });
  };

  // const initializePayment = usePaystackPayment({
  //   name: config.name,
  //   email: config.email,
  //   amount: pricing * 100,
  //   publicKey,
  // });

  const handleSubmit = (e) => {
    e.preventDefault();
    setUserData({
      name: config.name,
      email: config.email,
      // servicePrice: pricing,
    });
    setTable(title, pricing);
    navigate("/invoice");

    // initializePayment(()=>onSuccess(openModal), onClose);

    // setConfig({
    //   name: "",
    //   email: "",
    // });
  };

  return (
    <div className="fixed bg-modalColor text-black top-0 left-0 h-full w-full flex justify-center items-center">
      <div className="sm:w-[50%] w-[90%] h-[70vh] flex justify-between">
        <div className=" bg-white rounded-[15px] sm:p-[3em] p-[1.5em] pt-[3em] sm:w-[90%] w-[95%] ">
          <p>Fill in the following details</p>
          <Input
            type="text"
            placeholder="Full Name."
            tag="Full name."
            name="name"
            value={config.name}
            onChange={handleChange}
          />
          <Input
            type="text"
            placeholder="Email."
            tag="Email address."
            name="email"
            value={config.email}
            onChange={handleChange}
          />
          <Button text="Generate Invoice." onClick={handleSubmit} />

          {/* <div className="mt-6 text-[12px]">
            <p>Note: You will redirected to paystack to make payment.</p>
            <p>
              You would making a payment of {formatAccount.format(pricing)}.
            </p>
          </div> */}
        </div>

        <div className="flex flex-col justify-between  h-100">
          <div
            className="bg-white w-[40px] h-[40px] rounded-[20px] flex justify-center items-center cursor-pointer"
            onClick={openModal}
          >
            <FaRegTimesCircle size={35} className="text-primary" />
          </div>
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
