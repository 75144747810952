import React from 'react'
import Nasa from "../assets/nasa.jpg";
const About = () => {
  return (
    <div className='bg-aux'>
      <div className="text-black  py-[3em]">
        <div className="w-[80%] mx-auto">
          <p
            className="md:text-[42px] text-[32px] font-bold "
            data-aos="fade-in"
            data-aos-duration="2000"
          >
           About Us
          </p>
          <div className="flex md:flex-row flex-col justify-between items-center sm:my-[2em] my-[0] ">
            <div className="md:w-[50%] w-[100%] md:text-left text-center md:mt-0 mt-[1em]">
              <p
                className="text-[16px] font-[300] py-4 md:w-[100%] w-[100%] leading-[30px]"
                data-aos="fade-in"
                data-aos-duration="2000"
              >
                SAME Consultancy stands for Study Abroad Made Easy. As the name implies, it is an agency that has been uniquely positioned to make migration for studying and work purposes easy. Essentially, the start-up provides personalized admission and visa processing services to individuals who intend to study abroad.
              </p>

              <p
                className="text-[16px] font-[300] py-4 md:w-[100%] w-[100%] leading-[30px]"
                data-aos="fade-in"
                data-aos-duration="2500"
              >
               The goal is to go further than just helping these applicants fulfill their study abroad dreams. The company also provides work visa processing services for nurses in the UK and also ensures to coach prospective candidates on the modules of the IELTS exam. The countries that the company currently focuses on includes Belgium, Germany and the United Kingdom.
              </p>

              {/* <p
                className="text-[16px] font-[300] py-4 md:w-[100%] w-[100%]"
                data-aos="fade-in"
                data-aos-duration="2500"
              >
                Nwanneka loves to learn, acquire new skill, sing, travel and
                dance. Nwanneka has a growth mindset and is open to
                collaboration and new challenges.
              </p> */}
            </div>

            <div
              className="md:w-[35%] w-[100%] h-[500px] md:mt-0 mt-4 overflow-hidden"
              data-aos="zoom-in"
              data-aos-duration="3000"
            >
              <img
                src={Nasa}
                alt="Hero"
                className="w-[100%] h-[100%] object-cover rounded-[20px] shadow-lg"
                data-aos="fade-in"
                data-aos-duration="4000"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default About