import React from "react";
import Pricing from "../components/Pricing";

const Services = () => {
  return (
    <div className="bg-white ">
      <p className="py-[3em] text-center text-[36px] font-[700] text-primary bg-aux">
        Our services
      </p>
      <div className="grid sm:grid-cols-3 grid-col-1 items-center justify-items-center gap-[3em] mt-4 w-[85%] mx-auto py-[3em]">
        <Pricing
          pricing=""
          title="VISA APPLICATION (work visa)"
          img="https://images.unsplash.com/photo-1581553673739-c4906b5d0de8?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8dmlzYXxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=900&q=60"
        />
        <Pricing
          pricing="30000"
          title="CONSULTATION REPORT (for studies abroad)"
          img="https://images.unsplash.com/photo-1578574577315-3fbeb0cecdc2?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NHx8Y29uc3VsdGluZ3xlbnwwfHwwfHw%3D&auto=format&fit=crop&w=900&q=60"
        />
        <Pricing
          pricing="50000"
          title="CONSULTATION REPORT (for work)"
          img="https://images.unsplash.com/photo-1584432810601-6c7f27d2362b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8bnVyc2V8ZW58MHx8MHx8&auto=format&fit=crop&w=900&q=60"
        />
        <Pricing
          pricing="2500"
          title="IT TUTORIAL CLASSES (per hour)"
          img="https://images.unsplash.com/photo-1585829365343-ea8ed0b1cb5b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80"
        />

        <Pricing
          pricing="2000"
          title="IELTS LESSON (per hour)"
          img="https://images.unsplash.com/photo-1501504905252-473c47e087f8?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NHx8bGVzc29ufGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=900&q=60"
        />
      </div>
    </div>
  );
};

export default Services;
