import React from "react";

const Input = ({ type, placeholder, onChange, name, tag , value}) => {
  return (
    <div className="flex flex-col text-primary my-4 w-[100%]">
      <label htmlFor={name} className="text-[14px]">{tag}</label>
      <input
        type={type}
        placeholder={placeholder}
        onChange={onChange}
        name={name}
        className="my-3 border border-primary rounded py-2 pl-4 outline-none placeholder:text-[14px] text-[14px]"
        value={value}
      />
    </div>
  );
};

export default Input;
