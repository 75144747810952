import React from "react";
import { FiMail, FiPhoneCall, FiTwitter } from "react-icons/fi";

const Contact = () => {
  return (
    <div className="bg-primary">
      <p
        className="md:text-[42px] text-[32px] font-bold py-[2em] bg-aux text-primary text-center"
        data-aos="fade-in"
        data-aos-duration="2000"
      >
        Contact Us
      </p>
      <div className="py-[4em]">
        <div className="sm:w-[65%] w-[90%] mx-auto bg-aux text-primary py-[6em] sm:px-[4em] px-[2em] rounded-[1em]">
          <h1 className="text-[28px] text-center">Get In Touch.</h1>
          <div className="bg-primary h-[3px] w-[100px] mx-auto"></div>

          <p className="text-center my-4">
            How can we help you? We'd love to hear from you
          </p>

          <div className="flex justify-between items-center mt-8 sm:w-[80%] w-[90%] mx-auto sm:flex-row flex-col">
            <div className="">
              <div className="text-[12px] rounded-[20px] flex items-center bg-[#0cb1be] py-2 px-4">
                <FiMail />
                <p className="ml-4">info@example.com</p>
              </div>

              <div className="text-[12px] rounded-[20px] flex items-center bg-[#2ec37e] py-2 px-4 my-4">
              <FiTwitter /> <p className="ml-4">@SAMEConsultancy</p>
              </div>

              <div className="text-[12px] rounded-[20px] flex items-center bg-[#2d6cc5] py-2 px-4">
                <FiPhoneCall />
                <p className="ml-4">+234 312 3234 234</p>
              </div>
            </div>

            <div className="h-[150px] rounded w-[2px] bg-secondary sm:block hidden"></div>
            <div className="text-[14px] sm:mt-0 mt-[2em] ">
              <p>
                Lorem ipsum dolor sit,
                <br /> amet consectetur adipisicing
                <br /> elit. Unde, repellat.
                <br /> Belgium
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
